import styled from 'styled-components';

const Ima = styled.img`
  width: 50%;
  margin-top: 15;
}
`

const Divy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

function App(){
	return (
		<Divy>
		<Ima src="mindwill.gif" alt="spinner art gif"/>
		</Divy>
	)
}

export default App;

